import React, { useCallback, useState } from "react"
import { motion, useReducedMotion } from "framer-motion"
import { Link } from "gatsby"
import useClient from "../util/useClient"

export const PostDetail = ({ post }) => {
  return (
    <article className="post">
      <header className="post-top">
        <div className="content-container">
          <div className="content">
            <h1 id="post-title">
              {post.frontmatter.title}
              {post.frontmatter.title2 && (
                <span>{post.frontmatter.title2}</span>
              )}
            </h1>

            <h2>{post.frontmatter.subtitle}</h2>
          </div>
          <img
            src={post.frontmatter.image.publicURL}
            alt={post.frontmatter.imageAlt}
          />
        </div>
      </header>
      <div
        className="content-container post-content"
        dangerouslySetInnerHTML={{ __html: post.html }}
      />
    </article>
  )
}

export const PostItem = ({ post, open, handleOpen, handleClose }) => {
  const isClient = useClient()
  const reducedMotion = useReducedMotion()
  const reducedMotionTransition = useCallback(
    (transition) => ({
      transition: reducedMotion
        ? {
            duration: 0,
            type: "tween",
          }
        : transition,
    }),
    [reducedMotion]
  )
  const handleToggle = useCallback(() => {
    if (open) {
      handleClose()
    } else {
      handleOpen()
    }
  }, [open, handleOpen, handleClose])

  return (
    <motion.div
      className={`post-bar ${post.frontmatter.slug}`}
      onClick={handleToggle}
      onKeyPress={(e) => e.key === "Enter" && handleToggle()}
      tabIndex={isClient ? 0 : undefined}
      role={isClient ? "button" : undefined}
      initial={false}
      variants={{
        hide: {
          height: 200,
        },
        show: {
          height: 550,
        },
      }}
      animate={open ? "show" : "hide"}
      {...reducedMotionTransition({
        type: "spring",
        stiffness: 300,
        damping: 25,
        restDelta: 0.5,
        restSpeed: 10,
      })}
    >
      <div className="content-container">
        <div className="title-holder">
          {React.createElement(
            isClient ? React.Fragment : Link,
            isClient ? null : { to: `/posts/${post.frontmatter.slug}` },
            <h2>
              {post.frontmatter.title}
              {post.frontmatter.title2 && (
                <span>{post.frontmatter.title2}</span>
              )}
            </h2>
          )}
          <motion.div
            variants={{
              hide: {
                height: 0,
                ...reducedMotionTransition({ duration: 0.1 }),
              },
              show: {
                height: "auto",
                ...reducedMotionTransition({ when: "beforeChildren" }),
              },
            }}
            animate={open ? "show" : "hide"}
          >
            <motion.h3
              variants={{
                hide: {
                  opacity: 0,
                  ...reducedMotionTransition({ duration: 0.1 }),
                },
                show: {
                  opacity: 1,
                  ...reducedMotionTransition({ delay: 0.05 }),
                },
              }}
              animate={open ? "show" : "hide"}
            >
              {post.frontmatter.subtitle}
            </motion.h3>
            <motion.p
              variants={{
                hide: {
                  opacity: 0,
                  ...reducedMotionTransition({ duration: 0.1 }),
                },
                show: {
                  opacity: 1,
                  ...reducedMotionTransition({ delay: 0.05 }),
                },
              }}
              animate={open ? "show" : "hide"}
              dangerouslySetInnerHTML={{
                __html: post.frontmatter.snippet,
              }}
            />
            <motion.p
              variants={{
                hide: {
                  visibility: "hidden",
                  opacity: 0,
                  ...reducedMotionTransition({ duration: 0.1 }),
                },
                show: {
                  visibility: "visible",
                  opacity: 1,
                  ...reducedMotionTransition({ delay: 0.1 }),
                },
              }}
              animate={open ? "show" : "hide"}
            >
              <Link
                to={`/posts/${post.frontmatter.slug}`}
                className="button"
                aria-label={`Read more: ${post.frontmatter.title}`}
              >
                Read more
              </Link>
            </motion.p>
          </motion.div>
        </div>
        <div className="image-holder">
          <img
            loading="lazy"
            src={post.frontmatter.image.publicURL}
            alt={post.frontmatter.imageAlt}
          />
        </div>
      </div>
    </motion.div>
  )
}

export const PostItems = ({ posts }) => {
  const [currentOpen, setCurrentOpen] = useState(new Set())
  return (
    <section className="post-bars">
      {posts.edges.map((post) => (
        <PostItem
          key={post.node.frontmatter.slug}
          post={post.node}
          open={currentOpen.has(post.node.frontmatter.slug)}
          handleOpen={() =>
            setCurrentOpen(() => {
              const nextOpen = new Set()
              nextOpen.add(post.node.frontmatter.slug)
              return nextOpen
            })
          }
          handleClose={() =>
            setCurrentOpen((current) => {
              const nextOpen = new Set(current)
              nextOpen.delete(post.node.frontmatter.slug)
              return nextOpen
            })
          }
        />
      ))}
    </section>
  )
}
